import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Page } from "../components/Page";
import { YamlViewer } from "../components/YamlViewer"; 
import { Breadcrumb, RandomShapeIcon } from "../components/Base";
import { GiBlackBridge } from "react-icons/gi";
import { GrapheneService } from "../services/GrapheneService";
import { CRUPills } from "../components/PillsWidgets";



export const Reserve = ({}) => {

    const { id } = useParams();
    const [reserve, setReserve] = useState();
    const [reserveObject, setReserveObject] = useState();
    const [providerBlockObject, setProviderBlockObject] = useState();

    useEffect(() => {
        GrapheneService.getReserveById(id).then((res) => {
            setReserve(res.data);
            let reserveObject = {...res.data};
            reserveObject.tokenSymbol = reserveObject.tlcs.tokenSymbol;
            delete reserveObject.tlcs;
            setReserveObject(reserveObject);
        });
    }, [id]);

    useEffect(()=>{
        if(!reserveObject) return;
        GrapheneService.getProviderBlockByReserveId(reserveObject.provider, id).then((res) => {
            setProviderBlockObject(res.data);
        });
    },[reserveObject]);


    return (
        <Page>
            {reserveObject && <div className="p-4 flex flex-col">
                <Breadcrumb paths={[{"name":"All Reserves","url":"/reserves"},{"name":reserveObject?.name,"url":`/reserves/${id}`, "active":false}]} />
                <div className="flex flex-row">
                    <div className="font-bold text-yellow-400 text-3xl">
                        {reserveObject.tokenSymbol} {reserveObject.amount}</div>                      
                </div>
                <div className="flex flex-row justify-start">                    
                    <GiBlackBridge className="text-2xl text-lime-400 mx-2"/> 
                    <span className="font-bold text-lg">
                        {reserveObject.provider}
                    </span>                   
                </div>
                <div className="flex flex-row">
                    <div className="text-lg">{reserveObject.name}</div>                      
                </div>

                {reserveObject?.bridged_crus.length>0 &&<div className="flex flex-row mt-2">                
                    <CRUPills crus={reserveObject.bridged_crus} />    
                </div>}
                {providerBlockObject?.id &&<div className="flex flex-row mt-2">
                    <div>
                        <div className="text-2xl font-bold">Block Info</div>
                        <YamlViewer hashedInfo={providerBlockObject}/>
                    </div>    
                </div>}
                {reserveObject?.name &&<div className="flex flex-row mt-2">                                     
                    <div className="text-2xl font-bold">
                        <div>Reserve Info</div>
                        <YamlViewer hashedInfo={reserveObject}/>   
                    </div>     
                </div>}
            </div>}        
        </Page>
    );
};