import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {CgSpinnerTwo} from "react-icons/cg";
import { MdBackup } from "react-icons/md";
import { BiMessageCheck } from 'react-icons/bi';
import { TbCertificate } from 'react-icons/tb';

export const Breadcrumb = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((path, index) => (
          <li key={index} className={`breadcrumb-item${index === paths.length - 1 ? ' active' : ''}`}>
            {index === paths.length - 1 ? (
              path.name
            ) : (
              <Link to={path.url}>{path.name}</Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}


export const Alert = ({ background, text, children }) => {
  return (
    <div className={`p-2 flex flex-row rounded ${background} ${text} w-full`} role="alert">
      {children}
    </div>
  );
};

export const HelpAlert = ({ children, helpLink }) => {
  return (
    <Alert background="bg-pink-100" text="text-slate-800">
        <div className="flex flex-row justify-end">
            <div>{children}</div>
        </div>      
    </Alert>
  );
};

export const Modal = ({ showModal, setShowModal, children }) => {
  return (
    <>{showModal && 
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
              <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
                  <div className="flex flex-col w-full justify-end">
                      <div>
                          {children}
                      </div>
                      <div className="flex flex-row justify-end items-start">
                          <button onClick={()=>setShowModal(false)} className="btn-common">Cancel</button>
                      </div>


                  </div>
                  
              </div>
          </div>
      </div>
    }</>       
  );
};

export const Badge = ({ variant, children }) => {
  return <span className={`badge badge-${variant}`}>{children}</span>;
};

export const Spinner = ({textColor="yellow-200"}) => {
  return (
    <>
    <div className="inline-block w-9 h-9 border-2 rounded-full" role="status">
        <CgSpinnerTwo className={`bg-slate-700 w-8 h-8 animate-spin text-${textColor} rounded-full`}/>
    </div>
    </>
  )
};


export const XummStatusLookup = {
  "START": {message: "TX Created.", icon: <MdBackup className="text-3xl" />},
  "SUBSCRIBED": {message: "Sent TX To Xumm, Please Sign using Xumm Wallet.", icon: <BiMessageCheck className="text-3xl" />},
  "SIGNED": {message: "TX Signed.", icon: <TbCertificate className="text-3xl" />},
};

export const JsonFileReaderComponent = ({setJson, showJson=true}) => {

  const handleFile = (e) => {
    const json = JSON.parse(e.target.result);
    setJson(json);
  }

  const handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsText(file);
  }

  return (
    <div>
      <input type="file" accept=".json" onChange={e => 
            handleChangeFile(e.target.files[0])} /> 
    </div>
  );
}

const fixedInputClass="rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-slate-500 focus:z-10 sm:text-sm"

export const FormInputField = ({
    handleChange,
    value,
    labelText,
    labelFor,
    id,
    name,
    type,
    isRequired=false,
    placeholder,
    customClass=null
}) => {
    return(
        <div className="mb-2">
            <div>{labelText}</div>
            <label htmlFor={labelFor} className="sr-only">
              {labelText}
            </label>
            <input
              onChange={handleChange}
              value={value}
              id={id}
              name={name}
              type={type}
              required={isRequired}
              className={customClass != null ? customClass : fixedInputClass}
              placeholder={placeholder}
            />
          </div>
    )
}

export const Dropdown = ({
  options,
  onChange, 
  value,
  placeholder="Select an option"
}) => {

  const handleChange = (id) => {
    console.log("id", id);
    onChange(id);
  };
    
    const fixedInputClass = "bg-gray-200 appearance-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900"
    
    return(
      <>
        <div className="foo">
            <label className="sr-only">
              {placeholder}
            </label>
            <div className="bg-gray-200 flex flex-row justify-between rounded-sm focus:outline-none focus:ring-slate-500 focus:border-slate-500 focus:z-10 sm:text-sm">
              <select
                onChange={(e)=>handleChange(e)}
                defaultValue={value}
                className={fixedInputClass}
              >
              {options.map((item, idx) => (
                  <option key={idx} value={item.value}>{item.label}</option>
              ))}
              </select>
              {/* <AiFillCaretDown className="text-slate-500 text-2xl mt-2" /> */}
            </div>
        </div>
      </>
    )
  };

export const ErrorList = ({customClass, errors}) => {
  const fixedInputClass="sm:text-sm text-red-600 break-words"

  const ulClass = "text-sm text-red-600"
  const liClass = "text-sm text-red-600"

  return (
    <div className={fixedInputClass+" "+customClass}>
      <ul className={ulClass}>
        {errors.map((error, idx) => (
          <li key={idx} className={liClass}>
          {error}
          </li>
        ))}
      </ul>
    </div>
  );
};
