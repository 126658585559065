import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FaPen,
  FaUser,
  FaUserTie
} from "react-icons/fa";


import {GiStoneBridge} from "react-icons/gi";
import {AiFillFire} from "react-icons/ai";
import {BsFillArrowRightCircleFill} from "react-icons/bs";
import {MdAccountBalance} from "react-icons/md";

import jwt from "jsonwebtoken";

import { Page } from "../components/Page";
import { FormInputField, Spinner, ErrorList } from "../components/Base";
import { GrapheneService } from "../services/GrapheneService";
import { XrplPayloadService } from "../services/XrplPayloadService";
import { useAuthStore } from "../store";
import {apiConfig} from "../env";
import { SignSendModal } from "../components/SignSendModel";

const handleAddWallet = (wallet, wallets) => {
    console.log("handleAddWallet: ", wallet);
    const n_wallets = [...wallets, wallet];
    n_wallets.sort((a, b) => (a.name > b.name ? 1 : -1));
    // setWallets(n_wallets);
    return n_wallets;
};

const handleUpdateWallet = (wallet, wallets) => {
    console.log("handleUpdateWallet: ", wallet, wallets);
    const index = wallets.findIndex((element) => element.id === wallet.id);
    if (index !== -1) {
        wallets[index] = wallet;
        useAuthStore.getState().setWallet(wallet);
    }

    const n_wallets = [...wallets];
    n_wallets.sort((a, b) => (a.name > b.name ? 1 : -1));
    return n_wallets;
};


const WalletList = ({ wallets, setWallets, wallet, setWallet }) => {


  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState();
  const [showModal, setShowModal] = useState(false);
  const [editWallet, setEditWallet] = useState();
  const [showModalTx, setShowModalTx] = useState(false);
  const [txPayload, setTxPayload] = useState(null);
  const [txResult, setTxResult] = useState(null);
  const [signPayload, setSignPayload] = useState(null);
  const [bridgeWalletId, setBridgeWalletId] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    setEditWallet({});
  }, [wallets, wallet]);

  const handleEditWallet = (wallet_id) => {
    // console.log("handleEditWallet", wallet);
    // get the wallet from the wallets by id
    let wallet = wallets.find((w) => w.id === wallet_id);
    console.log("wallet to edit:", wallet);
    setEditWallet(wallet);
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const handleBridgeAccountSet = (bridgeWalletId) => {
    console.log("handleBridgeAccountSet", bridgeWalletId);
    setBridgeWalletId(bridgeWalletId);
    setLoading(true);

    // need to use the vintage from the trustline
    const bridgeRequest = {
        "bridgeWalletId":bridgeWalletId
    }

    XrplPayloadService.postBridgeAccountsetPayload(bridgeRequest)
    .then((result) => {
        console.log("postBridgeAccountsetPayload result", result.data);
        const payload = result.data;
        //lets sign and send the payload
        setTxPayload(payload);
        setLoading(false);
        setShowModalTx(true);    
    }).catch((error) => {
        console.log("getAssertionPayload error", error);
        if (error.response) {
            setError(error.response.data.detail);
        } else {
            setErrors(["Problem XrplPayloadService.postBridgeAccountsetPayload"]);
        }
        setLoading(false);
    });
  };

  const setDefaultWallet = (wallet_id) => {
    console.log("setDefaultWallet", wallet_id);

    // get the jwt from the store
    let jwt_stored = useAuthStore.getState().jwt;
    console.log("jwt:", jwt.decode(jwt_stored));
    let email = jwt.decode(jwt_stored).sid;
    console.log("email:", email, wallet_id);
    GrapheneService.updateUser(email, wallet_id)
      .then((r) => {
        console.log(r);
        setWallet(r.data.default_wallet);
        useAuthStore.getState().setWallet(r.data.default_wallet);
        setSuccessMessage(
          `Wallet ${r.data.default_wallet.name} set as default`
        );
        setTimeout(() => {
          setSuccessMessage(null);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="flex flex-col p-1">
      {errors && errors.length > 0 && (
        <div className="m-2 alert-danger text-center">
          <ErrorList errors={errors} customClass={"flex flex-row"}/>
        </div>
      )}
      {successMessage && (
        <div className="ml-1 mr-3 bg-cyan-200 rounded-lg w-full p-2 text-cyan-800 text-center flex flex-col">
          <div>{successMessage}</div>
        </div>
      )}
      {wallets && wallets.length > 0 ? 
        <>
            <div className="flex flex-row">
                <WalletModal
                wallets={wallets}
                setWallets={setWallets}
                setShowModal={setShowModal}
                showModal={showModal}
                editWallet={editWallet}
                handleAddWallet={handleAddWallet}
                handleUpdateWallet={handleUpdateWallet}
                />
            </div>
            <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-0 text-left">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>            
                    <th>Description</th>
                    <th>Domain</th>
                    <th scope="col" className="text-center">
                    <div className="w-full flex-row flex justify-center text-center">
                        Actions
                    </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {wallets.map((item, index) => (
                    <tr key={index} className={wallet && item.id === wallet.id ? 
                      "mb-1 bg-slate-700":
                      "mb-1"}>
                    <td className="font-mono flex flex-row">
                        <BsFillArrowRightCircleFill
                            onClick={() => setDefaultWallet(item.id)}
                            className={wallet && item.id === wallet.id ? 
                              "link-common mr-2 text-2xl text-green-400":
                              "link-common mr-2 text-2xl text-gray-400"}
                        />
                        {item.id}</td>
                    <td>
                        <div className="flex flex-row items-center justify-start">
                          <div
                              className="mr-2 div-link text-sm font-bold text-cyan-400 underline hover:text-yellow-300"
                              onClick={() => navigate(`/wallet/${item.id}`)}
                          >
                              {item?.name}
                          </div>
                          {item?.is_burner && <div>
                            <span className="items-center"><AiFillFire className="text-2xl text-pink-500"/></span></div>}
                            {/*determine if bridge wallet is set*/}
                            {item?.is_bridge && <div>
                            <span><GiStoneBridge className="text-2xl text-pink-500"/></span>
                          </div>}

                          {item?.is_buyer && <div>
                            <span><FaUserTie className="text-lg text-pink-500"/></span>
                          </div>}

                        </div>
                    </td>
                    <td>
                        <div onClick={()=>window.open(`${apiConfig().ledgerExplorer}/accounts/${item.classic_address}`,'_blank')} className="link-common break-words w-fit font-mono text-xs font-bold">
                        {item.classic_address}
                        </div>
                    </td>
                    <td>
                        <div className="break-words w-fit font-mono text-sm">
                        {item.description}
                        </div>
                    </td>
                    <td>
                        <div className="break-words w-fit font-mono text-sm text-cyan-300">{item?.domain}</div>
                    </td>
                    <td>
                        <div className="flex flex-row items-center justify-center">
                        <FaUser
                            onClick={() => setDefaultWallet(item.id)}
                            className="link-common mr-2"
                        />
                        <FaPen
                            onClick={() => handleEditWallet(item.id)}
                            className="link-common mr-2"
                        />
                        {item?.is_bridge && !item?.bridge_accountset && 
                          <div>
                            <MdAccountBalance onClick={() => handleBridgeAccountSet(item.id)}
                              className="link-common mr-2 text-xl"/>
                          </div>}
                        </div>
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <SignSendModal 
                setTxResult={setTxResult}
                showModal={showModalTx} 
                setShowModal={setShowModalTx}
                payload={txPayload}/>
        </>:
        <>No wallets. Create a wallet.</>}
    </div>
  );
};

const walletFormFields = [
  {
    labelText: "Wallet Name",
    labelFor: "name",
    id: "name",
    name: "name",
    type: "text",
    autoComplete: "name",
    isRequired: true,
    placeholder: "Wallet Name",
  },
  {
    labelText: "Description",
    labelFor: "description",
    id: "description",
    name: "description",
    type: "text",
    isRequired: false,
    placeholder: "Description",
  },
  {
    labelText: "Is Burn Wallet?",
    labelFor: "is_burner",
    id: "is_burner",
    name: "is_burner",
    type: "checkbox",
    isRequired: false,
    placeholder: "Is Burn Wallet?",
    customClass: "w-[20px] h-[20px] rounded-full bg-slate-200 mb-2",
  },
  {
    labelText: "Is Bridge Wallet?",
    labelFor: "is_bridge",
    id: "is_bridge",
    name: "is_bridge",
    type: "checkbox",
    isRequired: false,
    placeholder: "Is Bridge Wallet?",
    customClass: "w-[20px] h-[20px] rounded-full bg-slate-200 mb-2",
  },
  {
    labelText: "Bridge Platform Name",
    labelFor: "bridge_type",
    id: "bridge_type",
    name: "bridge_type",
    type: "text",
    isRequired: false,
    placeholder: "Name of bridge platform"
  },
  {
    labelText: "Is Buyer Wallet?",
    labelFor: "is_buyer",
    id: "is_buyer",
    name: "is_buyer",
    type: "checkbox",
    isRequired: false,
    placeholder: "Is Buyer Wallet?",
    customClass: "w-[20px] h-[20px] rounded-full bg-slate-200",
  },
];

const WalletModal = ({
  showModal,
  setShowModal,
  handleAddWallet,
  handleUpdateWallet,
  wallets,
  setWallets,
  editWallet = null,
}) => {
  const [formState, setFormState] = useState({ 
    name: "", description: "", 
    is_burner: false, is_bridge: false, 
    is_buyer: false });

  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(walletFormFields);
  const [fieldsMap, setFieldsMap] = useState(null);
  
  useEffect(() => {
    setFormState({ 
      name: "", description: "", 
      is_burner: false, is_bridge: false, 
      is_buyer: false });
  }, [showModal]);
  
  useEffect(() => {
    if (editWallet) {
      console.log("update wallet modal: ", editWallet);
      let formState_n = { ...formState };
      fields.forEach((field) => {
        console.log("field: ", field);
        formState_n[field.id] = editWallet[field.id];
      });
      setFormState(formState_n);
    }
  }, [editWallet]);

  const handleChange = (e) => {

    if (e.target.id === "is_burner") {
      console.log("is_burner: ", e.target.checked);
      // setBurnToggle(e.target.checked);
      setFormState({ ...formState, [e.target.id]: e.target.checked });
    } 
    else if (e.target.id === "is_bridge") {
      console.log("is_bridge: ", e.target.checked);
      console.log("form state: ", formState);
      setFormState({ ...formState, [e.target.id]: e.target.checked });
    }
    else if (e.target.id === "is_buyer") {
      console.log("is_buyer: ", e.target.checked);
      // setBuyerToggle(e.target.checked);
      setFormState({ ...formState, [e.target.id]: e.target.checked });
    }
    else {
      setFormState({ ...formState, [e.target.id]: e.target.value });
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setSuccessMessage(null);
    const n_state = { ...formState };
    if (editWallet) {
      n_state["id"] = editWallet.id;
    }

    console.log("n_state to be sent to server: ", n_state);

    !editWallet
      ? GrapheneService.createWallet(n_state)
          .then((r) => {
            setLoading(false);
            console.log("auth response: ", r);
            setErrors([]);
            setSuccessMessage(
              `wallet creation of ${formState["name"]} successful`
            );
            const n_wallets = handleAddWallet(r.data, wallets);
            setWallets(n_wallets);
            setFormState({ 
              name: "", description: "", 
              is_burner: false, is_bridge: false, 
              is_buyer: false });
            setTimeout(() => {
                setSuccessMessage(null);
                setShowModal(false);
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setFormState({ 
              name: "", description: "", 
              is_burner: false, is_bridge: false, 
              is_buyer: false });
            if (e.response && e.response.data) {
              setErrors([e.response.data.error]);
            } else if (e.message) {
              setErrors([e.message]);
            } else {
              setErrors(["Error creating user"]);
            }
          })
      : GrapheneService.updateWallet(n_state)
          .then((r) => {
            setLoading(false);
            console.log("auth response: ", r);
            setErrors([]);
            setSuccessMessage(
              `wallet update of ${formState["name"]} successful`
            );
            const n_wallets = handleUpdateWallet(r.data, wallets);
            setWallets(n_wallets);
            setFormState({ 
              name: "", description: "", 
              is_burner: false, is_bridge: false, 
              is_buyer: false });
            setTimeout(() => {
                setSuccessMessage(null);
                setShowModal(false);
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setFormState({ 
              name: "", description: "", 
              is_burner: false, is_bridge: false, 
              is_buyer: false });
            if (e.response && e.response.data) {
              setErrors([e.response.data.error]);
            } else if (e.message) {
              setErrors([e.message]);
            } else {
              setErrors(["Error creating user"]);
            }
          });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowModal(false);
    setErrors([]);
  };

  return (
    <>
      {!editWallet && (
        <div onClick={() => setShowModal(true)} className="btn-common">Create Wallet</div>
      )}
      
      {showModal && (
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-10 outline-none focus:outline-none">
          <div className="bg-gray-900 bg-opacity-80 p-4 flex flex-row justify-center h-full items-start">
            <div className="rounded bg-cyan-200 w-[350] p-2 text-slate-800">
              {errors && errors.length > 0 && (
                <div className="m-2 alert-danger text-center">
                  <ErrorList errors={errors} />
                </div>
              )}
              {successMessage ? (
                <div className="m-2 alert-success text-center flex flex-col">
                  <div>{successMessage}</div>
                </div>
              ) : (
                <>
                  <div className="text-lg">{editWallet ? <>EDIT</> : <div>CREATE</div>} Wallet</div>
                  {loading && (
                    <div className="flex flex-row w-full justify-center">
                      <Spinner />
                    </div>
                  )}
                  <div>
                    <>
                      <div>
                        {
                          fields.map((field) => (
                            <FormInputField
                              key={field.id}
                              handleChange={handleChange}
                              labelText={field.labelText}
                              labelFor={field.labelFor}
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              value={
                                formState[field.id] ? formState[field.id] : ""
                              }
                              isRequired={field.isRequired}
                              placeholder={field.placeholder}
                              customClass={field.customClass}
                            />
                          ))
                          // fieldsMap
                        }
                      </div>
                      <div className="mt-2 flex flex-row items-center justify-between">
                        <div
                          onClick={(e) => handleCancel(e)}
                          className="btn-common flex w-1/2"
                        >
                          Cancel
                        </div>
                        {!editWallet ? (
                          <div
                            onClick={(e) => handleSubmit(e)}
                            className="btn-common flex w-1/2"
                          >
                            Create Wallet
                          </div>
                        ) : (
                          <div
                            onClick={(e) => handleSubmit(e)}
                            className="btn-common flex w-1/2"
                          >
                            Update Wallet
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Wallets = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null);
    GrapheneService.getUser()
      .then((r) => {
        setWallet(r.data.default_wallet);
        useAuthStore.getState().setWallet(r.data.default_wallet);
        GrapheneService.getWallets().then((r) => {
          console.log("getWallets response: ", r);
          setWallets(r.data);   
          setLoading(false);
        });
      })
      .catch((e) => {
        console.log(e);

        if (e.message === "Network Error")
          setError(
            "Could not connect to the Graphene API. Please check your connection."
          );
        else setError(e.message);

        setLoading(false);
      });
  }, []);

  return (
    <Page>
      <div className="p-2">
        {error && (
          <div className="p-2 font-bold error rounded bg-red-200 text-red-700">
            {error}
          </div>
        )}
        <div className="flex flex-row justify-between">
          <div className="text-3xl">Wallets</div>
          <div className="flex flex-row justify-end p-1">
            <div className="flex flex-row">
              <WalletModal
                wallets={wallets}
                setWallets={setWallets}
                setShowModal={setShowModal}
                showModal={showModal}
                handleAddWallet={handleAddWallet}
                handleUpdateWallet={handleUpdateWallet}
                editWallet={false}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <WalletList
            wallets={wallets}
            setWallets={setWallets}
            wallet={wallet}
            setWallet={setWallet}
          />
        )}
      </div>
    </Page>
  );
};
