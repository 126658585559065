import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Page } from "../components/Page";
import { GrapheneService } from "../services/GrapheneService";
import { GiBlackBridge } from "react-icons/gi";

const ReservesList = ({ reserves }) => {
    const navigate = useNavigate();
  
    return (
      <div className="flex flex-col w-full">
        {reserves.length>0 ? <table className="table-auto border-separate border-spacing-y-2 border-spacing-x-1 text-left">
          <thead>
            <tr>
                <th></th>
                <th>Provider</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Token</th>
                <th>Project</th>
            </tr>
          </thead>
          <tbody>
            {reserves && reserves.map((item, index) => (
              <tr key={index} className="mb-1">
                <td>
                    {item?.type === "BRIDGE" && <GiBlackBridge className="text-3xl text-lime-300"/>}
                </td>
                <td>
                  {item?.provider}
                </td>
                <td>
                  <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/reserves/${item.id}`)}
                  >
                    {item?.name}
                  </div>
                </td>
                <td className="font-bold font-mono text-3xl text-yellow-400">
                  {item?.amount}
                </td>
                <td className="font-bold font-mono text-yellow-400">
                    <div
                    className="text-lg font-bold div-link text-yello-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/tokens/${item.tlcs.id}`)}>
                        {item?.tlcs.tokenSymbol}
                    </div>
                </td>
                <td className="break-words max-w-[300]">
                    {item?.tlcs?.project && <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/projects/${item?.tlcs?.project.id}`)}>{item?.tlcs?.project?.project_name}</div>}
                        
                </td>
                {/* <td className="break-words max-w-[300]">
                  {item?.iwa_id}
                </td>
                <td className="break-words max-w-[300]">
                    {item?.project && <div
                    className="text-sm font-bold div-link text-cyan-400 underline hover:text-yellow-300"
                    onClick={() => navigate(`/projects/${item?.project.id}`)}>{item?.project?.project_name}</div>}
                        
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>:<>No Reserves For Wallet</>}
      </div>
    );
};


export const Reserves = ({}) => {
    const [reserves, setReserves] = useState([]);

    useEffect(() => {
        GrapheneService.getReserves().then((res) => {
            setReserves(res.data);
        });
    }, []);

    return (
        <Page>
            <div className="p-4 flex flex-col">
                <div className="flex flex-row">
                    <div className="text-3xl">Reserves</div>                      
                </div>
                <div className="flex flex-row">
                    <ReservesList reserves={reserves}/>
                </div>
            </div>         
        </Page>
    );
};