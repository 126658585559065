import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {FormInputField, ErrorList, Spinner} from "../components/Base";
import imgIcon64 from "../assets/img/centigrade_icon2_128.png";
import { GrapheneService } from "../services/GrapheneService";

const signupFields=[
    {
        labelText:"Email address",
        labelFor:"email-address",
        id:"email-address",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address",
        customClass: "p-1 w-full rounded mb-2 text-gray-600",      
    },
    {
        labelText:"Password",
        labelFor:"password",
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Password",
        customClass: "p-1 w-full rounded mb-2 text-gray-600",      
    },
    {
        labelText:"Confirm Password",
        labelFor:"confirm-password",
        id:"confirm-password",
        name:"confirm-password",
        type:"password",
        autoComplete:"confirm-password",
        isRequired:true,
        placeholder:"Confirm Password",
        customClass: "p-1 w-full rounded mb-2 text-gray-600",   
    }
]

const SignupForm = ({fields}) => {

    const [errors, setErrors] = useState([]);
    const [successMessage,setSuccessMessage]=useState();
    const [loading, setLoading] = useState(false);

    const validateEmail = (email, errors) => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        if (!emailRegex.test(email)) {
        //   setErrors([...errors,"Email is invalid"]);
          errors.push("Email is invalid");
        } 
      };
    
      const validatePassword = (password, errors) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        if (!passwordRegex.test(password)) {
            errors.push("Password is invalid, Must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number");
        }      
      };

    // const fields=loginFields;
    let fieldsState = {};
    fields.forEach(field=>fieldsState[field.id]='');

    const [loginState,setLoginState]=useState(fieldsState);

    const handleChange=(e)=>{
        setErrors([]);
        setLoginState({...loginState, [e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        setErrors([]);
        console.log("loginState: ",loginState);
        const nE = []
        if (loginState["password"]!==loginState["confirm-password"]) {
            console.log("passwords don't match");
            // setErrors([...errors,"Passwords don't match"]);
            nE.push("Passwords don't match");
        }

        validateEmail(loginState["email-address"], nE);
        validatePassword(loginState["password"], nE);
        console.log("errors: ",nE);
        setErrors(nE);
        if (nE.length===0) {
            setErrors([]);
            console.log("no errors");
            // navigate("/signup");
            setLoading(true);
            GrapheneService.createUser(loginState["email-address"], loginState["password"])
            .then(r=>{
                console.log(r);
                setSuccessMessage(`User created successfully, please look for a verification email sent to ${loginState["email-address"]}`);
                setLoading(false);
            })
            .catch(e=>{
                console.log(e);
                if(e.response && e.response.data) {
                    setErrors([e.response.data.error]);
                } else if (e.message) {
                    setErrors([e.message]);
                } else {
                    setErrors(["Error creating user"]);
                }
                setLoading(false);
            });
        
        }
        

    }

    return(
        <form className="mt-1">
            {loading && <div className="flex flex-row justify-center w-full"><Spinner/></div>}
            {errors && errors.length>0 && 
            <div className="m-2 alert-danger text-center">
                <ErrorList errors={errors}/>
            </div>}
            {successMessage ?
            <div className="m-2 alert-success text-center">
                {successMessage}
            </div>:<>          
            <div className="p-2 m-2">
                <div className="italic text-gray-400">Passwords should...</div>
                <ul className="list-decimal">
                    <li>Be at least 8 characters long.</li>
                    <li>Contain at least one lowercase letter.</li>
                    <li>Contain at least one uppercase letter.</li>
                    <li>Contain at least one number.</li>
                    <li>Contain at least one special character (e.g., !@#$%^&*).</li>
                </ul>
            </div>
            <div>
            {
                fields.map(field=>
                        <FormInputField
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                            customClass={field.customClass}
                    />
                
                )
            }
        </div>
        <div className="flex flex-row items-center justify-between">
            <div onClick={(e)=>handleSubmit(e)} className="btn-common flex w-[300]">
                Signup
            </div>
        </div>
            </>}

      </form>
    )
}

export const Signup = ({
    xumm=null,
    runtime=null}) => {
        const navigate = useNavigate();
        return (
            <div>
                <div className="flex flex-row justify-center mt-4">
                    <div className="flex flex-col w-[300]">
                        

                        <div className="text-3xl font-semibold flex fle-row justify-start p-2 items-center">
                            <img src={imgIcon64} alt="graphene" className="mr-2 inline-block h-10 align-middle" />
                            {/* <span className="font-heading">graphene</span><span className="font-heading text-cyan-200">impact</span> */}
                        </div>

                        <div className="text-3xl w-100 text-center">Signup</div>
                        <SignupForm fields={signupFields}/> 
                        <div className="w-100 text-center">Already have an account? <span onClick={()=>navigate("/login")} className="ml-3 text-yellow-500 link-common">Login</span></div>

                        <div className="w-100 text-center">Not Ready? <span onClick={()=>navigate("/")} className="ml-3 text-yellow-500 link-common">Goto Home</span></div>


                    </div>
                </div>
            </div>
        );
};