import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import {GoAlert} from "react-icons/go";

export const ReservePills = ({ reserves }) => {

    const navigate = useNavigate();

    return (
      <>
        {reserves &&
        <div>
            <div className="flex flex-row justify-start font-bold">Reserves</div>
            <div className="flex flex-wrap">
                {reserves.map((reserve) => {
                    return <span onClick={()=>navigate(`/reserves/${reserve.id}`)} key={reserve.id} className="bg-purple-800 rounded-full m-1 p-1 text-xs hover:underline hover:cursor-pointer">
                        {reserve.type} {reserve.provider} {reserve.amount}</span>})
                }
            </div>
        </div>}
      </>
    );
};

export const BuyersPills = ({ buyers }) => {
    
    const navigate = useNavigate();

    return (
        <>
            {buyers && buyers.length>0 &&
            <div className={`flex flex-col rounded w-full`} role="alert">
                <div className="flex flex-row justify-start font-bold">Buyers</div>
                <div className="flex flex-wrap">
                {buyers.map((buyer) => {
                    return <>
                        <div 
                        onClick={()=>navigate(`/wallet/${buyer.id}`)}
                        key={buyer.id} 
                        className="bg-blue-800 rounded-full m-1 p-1 text-xs hover:underline hover:cursor-pointer flex flex-row justify-center items-center">
                            <span>{buyer.name}</span>
                            {buyer?.trustset_tx_hash ? 
                                <FaCheckCircle className="text-green-500 ml-1"/>:
                                <GoAlert className="text-yellow-500 ml-1"/>}
                        
                        </div>
                        
                         
                        
                    </>              
                    })
                }
                </div>
            </div>}
        </> 
      
    );
};

export const CRUPills = ({ crus }) => {
    return (
      <>
        {crus &&
        <div className="mb-2">
            <div className="flex flex-row justify-start font-bold">Carbon Units</div>
            <div className="flex flex-wrap">
            {crus.map((cru) => {
                if (cru.carbon_credit_status === 'ACTIVE') {
                    return <span key={cru.credit_unique_id} 
                    className="bg-cyan-800 font-mono rounded-full m-1 p-1 text-xs">
                        {cru.credit_unique_id}</span>}
                else if (cru.carbon_credit_status === 'RETIRED') {
                    return <span key={cru.credit_unique_id} 
                    className="bg-slate-400 text-slate-600 font-mono rounded-full m-1 p-1 text-xs">
                        {cru.credit_unique_id}</span>
                }    
                else if (cru.carbon_credit_status === 'BRIDGED') {
                    return <span key={cru.credit_unique_id} 
                    className="bg-yellow-800 text-yellow-300 font-mono rounded-full m-1 p-1 text-xs">
                        {cru.credit_unique_id}</span>
                }    
            })}
            </div>
            <div className="flex flex-row text-xs">
                <span className="mr-1">Key:</span>
                <div className="p-1 mr-1 bg-cyan-800 rounded">active</div>
                <div className="p-1 mr-1 bg-yellow-800 text-yellow-300 rounded">retired by bridge</div>
                <div className="p-1 mr-1 bg-slate-400 text-slate-600 rounded">retired</div>
            </div>
        </div>}
      </>
    );
};

export const BenefitClaimsPills = ({ benefitClaims, showTitle=true }) => {
    return (
        <>
          {benefitClaims && benefitClaims.length>0 &&
          <div>
              {showTitle && <div className="flex flex-row justify-start font-bold">Benefits</div>}
              <div className="flex flex-wrap">
                  {benefitClaims.map((benefitClaim) => {
                      return <span key={benefitClaim.id} className="bg-cyan-500 rounded-full m-1 p-1 text-xs font-bold uppercase">
                          {benefitClaim.name}</span>})
                  }
              </div>
          </div>}
        </>
      );
};

